import { getCurrentUser } from './userUtils';

export const rolePermissions: { [key: string]: string[] } = {
  registerGui: ['065 E'],
  registerFile: ['066 E'],
  cancelRequests: ['065 E', '066 E'],
  approveCounterpartyRequests: ['065 E', '066 E'],
  authorizeRequests: ['065 E'],
  viewContracts: ['064 E', '065 E', '066 E', '067 E', '068 I', '069 I'],
  duplicateContract: ['065 E'],
  requestCancelOrModifyContract: ['065 E'],
  requestAdvance: ['065 E'],
  scheduleAdvance: ['065 E'],
  subscribeContracts: ['067 E'],
  exportAutomaticReports: ['064 E', '065 E', '066 E', '067 E', '068 I'],
  generateCustomReports: ['064 E', '065 E', '066 E', '067 E', '068 I'],
  contractOptions: ['064 E', '065 E', '066 E', '067 E'],
  manageParticipants: ['068 I', '069 I'],
  manageFrameworkContracts: ['068 I', '069 I'],
  viewFrameworkContracts: [
    '064 E',
    '065 E',
    '066 E',
    '067 E',
    '068 I',
    '069 I',
  ],
};

export function hasPermission(permission: string): boolean {
  const user = getCurrentUser();
  const userRoles = user?.roles || [];

  if (!userRoles || userRoles.length === 0) return false;

  return userRoles.some((role: string) =>
    rolePermissions[permission]?.includes(role),
  );
}
