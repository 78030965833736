const locale = {
  messages: 'Messages',
  home: 'Home',
  requests: 'Requests',
  back: 'Back',
  seeMore: 'See more',
  advance: 'Advance',
  cancellation: 'Cancellation',
  authorization: 'Authorization',
  modification: 'Modification',
  registration: 'Registration',
  subscription: 'Subscription',
  register: 'Register',
  modify: 'Modify',
  cancel: 'Cancel',
  anticipate: 'Anticipate',
  subscribe: 'Subscribe',
  authorize: 'Authorize',
  noInformationToSee: `There isn't any information to display`,
  pagerInfo: '{{currentPage}} of {{totalPages}}',
  reject: 'Reject',
  negotiationDate: 'Negotiation Date',
  expirationDate: 'Expiration Date',
  operationType: 'Operation Type',
  transactionType: 'Counterparty Transaction Type',
  subscriptionType: 'Subscription Type',
  contractOwner: 'Contract Owner',
  counterparty: 'Counterparty',
  contractOwnerInvestor: 'Contract Owner Investor',
  counterpartyInvestor: 'Counterparty Investor',
  apiErrorMessage: 'An error has occurred. Please try again later.',
  folio_grid: 'Folio',
  id_contract_grid: 'Contract Id',
  contract_owner_grid_code: 'Contract owner code',
  contract_owner_grid_name: 'Contract owner name',
  counterparty_code: 'Counterparty code',
  counterparty_name: 'Counterparty name',
  type_operation: 'Operation type',
  date_exp_grid: 'Due date',
  state: 'State',
  nocional_amount: 'Nocional amount',
  pacted_price_grid: 'Pacted price',
  forward_price_grid: 'Forward price',
  reason_state_grid: 'Reason of state',
  transit_state_grid: 'Transit status',
  owner_principal_grid_code: 'Principal owner contract code',
  owner_principal_grid_name: 'Principal owner contract name',
  owner_secondary_grid_code: 'Counterparty principal code',
  owner_secondary_grid_name: 'Counterparty principal name',
  register_date_grid: 'Counterparty Registration Date',
  registrationDate: 'Registration Date',
  requestStatus: 'Request Status',
  requestType: 'Request Type',
};

export default locale;
