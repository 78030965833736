const locale = {
  messages: 'Mensajes',
  home: 'Inicio',
  requests: 'Solicitudes',
  back: 'Volver',
  seeMore: 'Ver más',
  advance: 'Anticipo',
  cancellation: 'Anulación',
  authorization: 'Autorización',
  modification: 'Modificación',
  registration: 'Registro',
  subscription: 'Suscripción',
  register: 'Registrar',
  modify: 'Modificar',
  cancel: 'Anular',
  anticipate: 'Anticipar',
  subscribe: 'Suscribir',
  authorize: 'Autorizar',
  noInformationToSee: 'No hay información para mostrar',
  pagerInfo: '{{currentPage}} de {{totalPages}}',
  reject: 'Rechazar',
  negotiationDate: 'Fecha de negociación',
  expirationDate: 'Fecha de vencimiento',
  operationType: 'Tipo de operación',
  transactionType: 'Tipo de transacción de la contraparte',
  subscriptionType: 'Tipo de suscripción',
  contractOwner: 'Dueño de contrato',
  counterparty: 'Contraparte',
  contractOwnerInvestor: 'Mandante dueño de contrato',
  counterpartyInvestor: 'Mandante de contraparte',
  apiErrorMessage:
    'Ha ocurrido un error. Por favor, inténtalo de nuevo más tarde.',
  folio_grid: 'Folio',
  id_contract_grid: 'Id de contrato',
  contract_owner_grid_code: 'Código dueño de contrato',
  contract_owner_grid_name: 'Nombre dueño de contrato',
  counterparty_code: 'Código contraparte',
  counterparty_name: 'Nombre contraparte',
  type_operation: 'Tipo de operación',
  date_exp_grid: 'Fecha de vencimiento',
  state_grid: 'Estado',
  nocional_amount: 'Monto contratado o nocional',
  pacted_price_grid: 'Precio pactado',
  forward_price_grid: 'Precio forward',
  reason_state_grid: 'Razón de estado',
  transit_state_grid: 'Estado de tránsito',
  owner_principal_grid_code: 'Código mandante dueño de contrato',
  owner_principal_grid_name: 'Nombre mandante dueño de contrato',
  owner_secondary_grid_code: 'Código mandante contraparte',
  owner_secondary_grid_name: 'Nombre mandante contraparte',
  register_date_grid: 'Fecha de registro de la contraparte',
  registrationDate: 'Fecha de registro',
  requestStatus: 'Estado de la solicitud',
  requestType: 'Tipo de solicitud',
};

export default locale;
