const locale = {
  TITLE: 'Anticipate',
  home: 'Home',
  search: 'Search',
  viewContract: 'View Contract',
  requests: 'Requests',
  pageDescription:
    'It is a long established fact that a reader will be distracted by the readable content of a page.',
  back: 'Back',
  contractAdvanceData: 'Contract Advance Data',
  compensatingParty: 'Compensating Party',
  advanceDate: 'Advance Date',
  advancePaymentDate: 'Advance Payment Date',
  advancePaymentForm: 'Advance Payment Form',
  complianceModality: 'Advance Compliance Modality',
  selectOption: 'Select an option',
  enterInformation: 'Enter Information',
  mandatoryField: 'Mandatory field',
  paymentCurrency: 'Advance Payment Currency',
  amountToCompensate: 'Amount to Compensate',
  advancePaymentAmount: 'Advance Payment Amount',
  paymentDate: 'Payment Date',
  paymentForm: 'Payment Form',
  anticipate: 'Anticipate',
  confirm: 'Confirm',
  subscribeAdvance: 'Subscribe Advance',
  authorizeAdvance: 'Authorize Advance',
  apiErrorMessage: 'An error has occurred. Please try again later.',
};

export default locale;
