import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { selectUser } from 'src/app/auth/user/store/userSlice';
import { useAuth } from 'src/app/auth/AuthRouteProvider';
import LanguageSwitcher from '../../LanguageSwitcher';
import Divider from '@mui/material/Divider';
import Dcv from '../style/assets/dcv.svg';
import Profile from '../style/assets/Profile.svg';
import Participante from '../style/assets/participante.svg';
import Alert from '../style/assets/alert.svg';
import Questions from '../style/assets/questions.svg';
import Configuration from '../style/assets/configuration.svg';
import profileMovil from '../style/assets/profileMovil.svg';
import Arrow from '../style/assets/arrow.svg';
import ConfigurationMovil from '../style/assets/configurationMovil.svg';
import Ayuda from '../style/assets/Ayuda.svg';
import Left from '../style/assets/Left.svg';
import { QuestionIconButton } from './IconsButtons';
import { ConfigurationIconButton } from './ConfigButton';

import { changeLanguage } from 'app/store/i18nSlice';
import i18next from 'i18next';
import en from '../i18n/en';
import es from '../i18n/es';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'app/store/store';
import HideSessionInformation from '../../HideSessionInformation';

i18next.addResourceBundle('en', 'top-menu', en);
i18next.addResourceBundle('es', 'top-menu', es);

function UserMenu() {
  const user = useSelector(selectUser);
  const { signOut } = useAuth();
  const [userMenu, setUserMenu] = useState<HTMLElement | null>(null);
  const dispatch = useAppDispatch();
  let localLanguage = localStorage.getItem('language');
  localLanguage = localLanguage != null ? localLanguage : 'es';
  const targetRef = useRef(null);

  useEffect(() => {
    dispatch(changeLanguage(localLanguage));
  }, []);

  const userMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  const [languageAnchorEl, setLanguageAnchorEl] = useState(null);

  const handleLanguageClick = (event) => {
    setLanguageAnchorEl(targetRef.current);
    handleClose();
  };

  const handleLanguageClose = () => {
    setLanguageAnchorEl(null);
  };

  const open = Boolean(languageAnchorEl);
  const id = open ? 'language-popover' : undefined;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleSvgClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { t } = useTranslation('top-menu');

  const open2 = Boolean(anchorEl);

  if (!user) {
    return null;
  }

  return (
    <div className="flex md:grid grid-cols-4 w-full content-center h-full">
      <div className="flex items-center justify-start md:border-r-1 border-[#C5C5C5] max-h-[47px] w-full">
        <div className="flex-col md:flex">
          <img src={Dcv} className="lg:hidden md:hidden" />
        </div>
        <div className="min-h-40 min-w-40 hidden md:flex">
          <img src={Profile} alt="" />
          <div className="mx-4 hidden flex-col items-baseline md:flex">
            <Typography component="span" className="flex font-semibold">
              {t('Greeting')}, {user.data.firstname} {user.data.lastname}
            </Typography>
            <Typography
              className="text-11 font-medium capitalize"
              color="text.secondary"
            >
              {user.data.rut}
            </Typography>
          </div>
        </div>
        <Popover
          className="hidden md:flex"
          open={Boolean(userMenu)}
          anchorEl={userMenu}
          onClose={userMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          classes={{
            paper: 'py-8',
          }}
        >
          {!user.role || user.role.length === 0 ? (
            <>
              <MenuItem component={Link} to="/sign-in" role="button">
                <ListItemIcon className="min-w-40">
                  <FuseSvgIcon>heroicons-outline:lock-closed</FuseSvgIcon>
                </ListItemIcon>
                <ListItemText primary={t('SignIn')} />
              </MenuItem>
            </>
          ) : (
            <MenuItem
              onClick={() => {
                signOut();
              }}
            >
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:logout</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary={t('SignOut')} />
            </MenuItem>
          )}
        </Popover>
      </div>

      <div className="hidden md:flex items-center justify-center border-r-1 border-[#C5C5C5] max-h-[47px] w-full">
        <div className="min-h-40 min-w-40 hidden md:flex">
          <img src={Participante} alt="" />

          <div className="mx-4 hidden flex-col items-baseline md:flex">
            <Typography component="span" className="flex font-semibold">
              {t('Participant')} {user.data.participantCode}
            </Typography>
            <Typography
              className="text-11 font-medium capitalize"
              color="text.secondary"
            >
              {user.data.participantName}
            </Typography>
          </div>
        </div>
      </div>

      <div className="flex items-center md:justify-center justify-end border-r-1 border-[#C5C5C5] max-h-[47px] w-full pr-5 gap-20">
        <Button className="hidden md:flex">
          <div>
            <img src={Alert} alt="" />
          </div>
        </Button>
        <QuestionIconButton />
        <ConfigurationIconButton />
        <Button className="lg:hidden md:hidden min-w-0">
          <div>
            <img src={Alert} style={{ width: '25px' }} />
          </div>
        </Button>
      </div>

      <div className="flex items-center ml-auto min-w-[180px] pl-5">
        <div style={{ color: '#1A39DA' }}>
          <Button
            className="min-h-40 min-w-40 lg:hidden md:hidden flex items-center"
            style={{ paddingLeft: '0.5rem', paddingRight: '0px' }}
          >
            <div className="min-w-[25px]">
              <img src={profileMovil} style={{ width: '25px' }} />
            </div>
            <div className="grid ml-8 mr-10 max-w-[130px] min-w-[100px]">
              <Typography className="text-left text-12 font-bold truncate ">
                {user.data.firstname} {user.data.lastname}
              </Typography>
              <Typography className="text-left text-12 capitalize">
                {user.data.rut}
              </Typography>
            </div>
            <div onClick={handleSvgClick} ref={targetRef}>
              <img src={Arrow} alt="" className="min-w-[20px]" />
            </div>
            <Popover
              id={id}
              open={open}
              anchorEl={languageAnchorEl}
              onClose={handleLanguageClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              slotProps={{
                paper: {
                  style: {
                    marginTop: '4rem',
                    width: '362px',
                    maxHeight: '350px',
                    display: 'flex',
                    flexDirection: 'column',
                  },
                },
              }}
            >
              <HideSessionInformation />
              <LanguageSwitcher />
            </Popover>
            <Popover
              id={id}
              open={open2}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              PaperProps={{
                style: {
                  marginTop: '13px',
                },
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '10px',
                }}
              >
                <Button className="min-h-40 min-w-40 p-0 md:px-16 md:py-6">
                  <img
                    src={Participante}
                    style={{ width: '40px', height: '40px' }}
                  />
                  <div className="mx-4 flex-col items-baseline text-left">
                    <Typography component="span" className="flex font-semibold">
                      {t('Participant')} {user.data.participantCode}
                    </Typography>
                    <Typography
                      className="text-11 font-medium capitalize"
                      color="text.secondary"
                    >
                      {user.data.participantName}
                    </Typography>
                  </div>
                </Button>
              </div>
              <Divider />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '10px',
                  marginLeft: '10px',
                }}
              >
                <div
                  onClick={handleLanguageClick}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src={ConfigurationMovil}
                    alt="Configuration"
                    className="w-[24px] h-auto"
                  />
                  <div style={{ marginLeft: '15px' }}>
                    <Typography
                      component="span"
                      className="flex"
                      style={{
                        color: '#1A39DA',
                        fontSize: '16px',
                        fontWeight: 400,
                      }}
                    >
                      {t('Settings')}
                    </Typography>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '10px',
                  marginLeft: '11px',
                }}
              >
                <img className="w-[24px] h-auto" src={Ayuda} alt="" />

                <div style={{ marginLeft: '15px' }}>
                  <Typography
                    component="span"
                    className="flex"
                    style={{
                      color: '#1A39DA',
                      fontSize: '16px',
                      fontWeight: 400,
                    }}
                  >
                    {t('Help')}
                  </Typography>
                </div>
              </div>
              <Divider />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '10px',
                  marginLeft: '11px',
                }}
              >
                <Button
                  className="min-h-40 min-w-40 p-0 md:px-16 md:py-6 md:flex"
                  onClick={signOut}
                  color="inherit"
                >
                  <div>
                    <img src={Left} style={{ width: '24px', height: '24px' }} />
                  </div>
                  <div
                    style={{
                      marginLeft: '15px',
                      color: '#1A39DA',
                      fontSize: '16px',
                      fontWeight: 400,
                    }}
                  >
                    {t('SignOut')}
                  </div>
                </Button>
              </div>
            </Popover>
          </Button>
        </div>

        <Button
          className="min-h-40 min-w-40 p-0 md:px-16 md:py-6 hidden md:flex lg:w-[215px] w-auto"
          onClick={() => {
            signOut();
          }}
          color="inherit"
          style={{
            border: '1px solid #1A39DA',
            color: '#1A39DA',
            borderRadius: 5,
          }}
        >
          {t('SignOut')}
          <div
            className="pl-5"
            onClick={() => {
              signOut();
            }}
          >
            <img src={Left} />
          </div>
        </Button>
      </div>
    </div>
  );
}

export default UserMenu;
