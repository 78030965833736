import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/system';
import SettingsIcon from '@mui/icons-material/Settings';
import { Badge, Popover, List } from '@mui/material';
import LanguageSwitcher from '../../LanguageSwitcher';
import { useState } from 'react';
import HideSessionInformation from '../../HideSessionInformation';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  border: '1px solid #1A39DA',
  borderRadius: '50%',
  padding: '6px',
  color: '#1A39DA',
  backgroundColor: 'transparent',
  transition: 'background-color 0.3s, color 0.3s',
  '&:hover': {
    backgroundColor: '#8137F2',
    border: '1px solid #8137F2',
    color: 'white',
  },
  '@media (max-width: 959px)': {
    padding: '5px',
  },
}));

const ConfigurationIconButton = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'notifications-popover' : undefined;

  return (
    <div>
      <StyledIconButton
        onClick={handleClick}
        color="inherit"
        className="hidden md:flex"
      >
        <SettingsIcon
          style={{ color: 'inherit' }}
          className="w-[16px] md:w-[25px] h-auto"
        />
      </StyledIconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            style: {
              marginTop: '10px',
              width: '362px',
              maxHeight: '350px',
              display: 'flex',
              flexDirection: 'column',
            },
          },
        }}
      >
        <HideSessionInformation />
        <LanguageSwitcher />
      </Popover>
    </div>
  );
};

export { ConfigurationIconButton };
