const locale = {
  Greeting: 'Hello',
  Participant: 'Participant',
  Organization: 'Banco BCI',
  SignIn: 'Sign In',
  SignUp: 'Sign Up',
  SignOut: 'Sign Out',
  Settings: 'Settings',
  Help: 'Help',
  LogOut: 'Log Out',
  English: 'English',
  Spanish: 'Spanish',
  Language: 'Language',
  HiddenSessionInfo: 'Session Information',
};

export default locale;
