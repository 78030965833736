const locale = {
  TITLE: 'Annulment',
  home: 'Home',
  search: 'Search',
  annulment: 'Annulment',
  pagerInfo: '{{currentPage}} of {{totalPages}}',
  contract: 'Contract',
  viewContract: 'View contract',
  requests: 'Requests',
  back: 'Back',
  authorizeCancellation: 'Authorize Cancellation',
  subscribeAnnulment: 'Subscribe Annulment',
  bulkAnnulmentsUnavailable: 'Bulk annulments cannot be processed at this time',
  'grid.noRecords': 'No records available.',
  apiErrorMessage: 'An error has occurred. Please try again later.',
};

export default locale;
