import { useState } from 'react';
import '../style/styles/MenuLateral.css';
import { useNavigate } from 'react-router-dom';
import Home from '../style/assets/home.svg';
import Relation from '../style/assets/registerMovil.svg';
import Aprove from '../style/assets/Aprove.svg';
import { hasPermission } from '../../../../shared-components/utils/permissions';

import i18next from 'i18next';
import en from '../i18n/en';
import es from '../i18n/es';
import { useTranslation } from 'react-i18next';

i18next.addResourceBundle('en', 'side-menu', en);
i18next.addResourceBundle('es', 'side-menu', es);

const SideMenuAdmin = ({ isCollapsed }) => {
  const [activeButton, setActiveButton] = useState(null);
  const { t } = useTranslation('side-menu');
  const navigate = useNavigate();
  const canExportReports = hasPermission('exportAutomaticReports');

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    if (buttonName === 'home') {
      navigate('/home');
    } else if (buttonName === 'approval') {
      navigate('/representative-approval');
    } else if (buttonName === 'relation') {
      navigate('/relation-participant');
    } else if (buttonName === 'search') {
      navigate('/search');
    } else if (buttonName === 'reports') {
      navigate('/reports');
    }
  };

  return (
    <div
      className={`menu-lateral flex flex-col gap-40 ${isCollapsed ? 'collapsed' : ''}`}
    >
      <div
        className={`menu-item ${isCollapsed ? 'icon-only' : ''}`}
        onClick={() => handleButtonClick('home')}
      >
        {isCollapsed ? (
          <div className="round-icon">{t('Home').charAt(0)}</div>
        ) : (
          <span className="button-content">{t('Home')}</span>
        )}
      </div>
      <div
        className={`menu-item ${isCollapsed ? 'icon-only' : ''}`}
        onClick={() => handleButtonClick('approval')}
      >
        {isCollapsed ? (
          <div className="round-icon">
            {t('ApprovalRepresentatives').charAt(0)}
          </div>
        ) : (
          <span className="button-content">{t('ApprovalRepresentatives')}</span>
        )}
      </div>
      <div
        className={`menu-item ${isCollapsed ? 'icon-only' : ''}`}
        onClick={() => handleButtonClick('relation')}
      >
        {isCollapsed ? (
          <div className="round-icon">{t('RelationParticipant').charAt(0)}</div>
        ) : (
          <span className="button-content">{t('RelationParticipant')}</span>
        )}
      </div>
      <div
        className={`menu-item ${isCollapsed ? 'icon-only' : ''}`}
        onClick={() => handleButtonClick('search')}
      >
        {isCollapsed ? (
          <div className="round-icon">{t('Search').charAt(0)}</div>
        ) : (
          <span className="button-content">{t('Search')}</span>
        )}
      </div>
      <div
        className={`menu-item ${isCollapsed ? 'icon-only' : ''} ${!canExportReports ? 'menu-option-disabled' : ''}`}
        onClick={() => handleButtonClick('reports')}
      >
        {isCollapsed ? (
          <div
            className={`${!canExportReports ? 'round-icon-disabled' : ''} round-icon `}
          >
            {t('Reports').charAt(0)}
          </div>
        ) : (
          <span className="button-content">{t('Reports')}</span>
        )}
      </div>
    </div>
  );
};

export default SideMenuAdmin;
