import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/system';
import { ContactSupportOutlined } from '@mui/icons-material';
import SettingsIcon from '@mui/icons-material/Settings';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  border: '1px solid #1A39DA',
  borderRadius: '50%',
  padding: '6px',
  color: '#1A39DA',
  backgroundColor: 'transparent',
  transition: 'background-color 0.3s, color 0.3s',
  '&:hover': {
    backgroundColor: '#8137F2',
    border: '1px solid #8137F2',
    color: 'white',
  },
  '@media (max-width: 959px)': {
    padding: '5px',
  },
}));

const QuestionIconButton = () => {
  return (
    <StyledIconButton className="hidden md:flex">
      <ContactSupportOutlined style={{ color: 'inherit' }} />
    </StyledIconButton>
  );
};
export { QuestionIconButton };
