const locale = {
  TITLE: 'Suscribir',
  home: 'Inicio',
  search: 'Buscar',
  viewContract: 'Ver Contrato',
  requests: 'Solicitudes',
  pageDescription:
    'Es un hecho bien establecido que un lector se distraerá con el contenido legible de una página.',
  back: 'Volver',
  contractId: 'ID contrato',
  contract: 'Contrato',
  registryDate: 'Fecha de negociación',
  originatorCode: 'Código Originador',
  originatorName: 'Nombre Originador',
  ownerContractCode: 'Código dueño de contrato',
  ownerContractName: 'Nombre dueño de contrato',
  investor: 'Mandante dueño del contrato',
  counterpartyCode: 'Código contraparte',
  counterpartyName: 'Nombre contraparte',
  counterpartyRUT: 'RUT contraparte',
  investorCode: 'Código mandante dueño de contrato',
  investorName: 'Nombre mandante dueño de contrato',
  counterpartyInvestorCode: 'Código mandante contraparte',
  counterpartyInvestorName: 'Nombre mandante contraparte',
  operationType: 'Tipo de operación',
  exchangeRateType: 'Tipo de cambio o índice referencial',
  clearingCurrencyCode: 'Moneda de compensación',
  forwardPrice: 'Precio Forward',
  payDayCode: 'Día de pago',
  maxSubscriptionTermType: 'Fecha máxima de suscripción',
  stateReason: 'Razón de estado',
  transactionType: 'Tipo de transacción',
  complianceModeType: 'Modalidad de cumplimiento',
  notionalAmount: 'Monto contado o nocional',
  agreedPrice: 'Precio pactado',
  paymentMethodType: 'Forma de pago',
  dueDate: 'Fecha de vencimiento',
  contractDate: 'Fecha de registro',
  status: 'Estado',
  operationStatus: 'Estado de la Operación',
  statusInTransit: 'Estado en tránsito',
  dcvFolio: 'Folio',
  afpTicker: 'Nemotécnico AFP',
  pagerInfo: '{{currentPage}} de {{totalPages}}',
  subscribe: 'Suscribir',
  recordCount: 'Número de registros:',
};

export default locale;
